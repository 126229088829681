import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAssetUrl } from '../services/cdn'
import { Container as BlockContainer } from '../components/Blocks'
//import { Container as BlockContainer, Quote } from '../components/Blocks'
import { Hero, CoreValues } from '../components/Sections'
//import { HeroCarousel, Hero, CoreValues } from '../components/Sections'
import { Container, Row, Col } from 'reactstrap'

const IndexPage = () => (
  <Layout>
    <SEO title="About" keywords={[`gatsby`, `application`, `react`]} />
    <Hero src="app/DSCN1464.jpg" size={6} height={null}>
      <h1 className="display-1 text-left text-white">OUR WAY</h1>
      <p className="lead"></p>
    </Hero>
    <BlockContainer padding={5}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">SIDC Agriculture Tale</h1>
              <p>In order to address the constraints, Sorosoro Ibaba Development Cooperative have made a collective action in production and marketing of hogs and its products. Situated in Batangas, SIDC has been helping its members and the communities in attaining a sustainable and viable hog business ventures.</p>
              <p>Its engagement in Contract Growing started on October of 1972 where SIDC engage to Contract Growing of Broiler as one of its pioneer business.</p>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    {/* <Quote>God! O my friend -- but it is too much for my strength -- I sink under the weight of the splendour of these visions!&nbsp;When, while the lovely valley teems with vapour around me.</Quote>
    <BlockContainer padding={3}>
      <Container>
        <Row>
          <Col md="6" className="my-3 px-4">
            <p className="mb-0">But a few stray gleams steal into the inner sanctuary, I throw myself down among the tall grass by the trickling stream; and, as I lie close to the earth, a thousand unknown plants are noticed by me: when I hear the buzz of the little world among the stalks, and grow familiar with the countless indescribable forms of the insects and flies, then I feel the presence of the Almighty, who formed us in his own image.</p>
          </Col>
          <Col md="6" className="border-left my-3 px-4">
            <p className="mb-0">But a few stray gleams steal into the inner sanctuary, I throw myself down among the tall grass by the trickling stream; and, as I lie close to the earth, a thousand unknown plants are noticed by me: when I hear the buzz of the little world among the stalks, and grow familiar with the countless indescribable forms of the insects and flies, then I feel the presence of the Almighty, who formed us in his own image.</p>
          </Col>
        </Row>
        <Row>
          <Col md="3"><img alt="ANNIVERSARY" className="img-fluid d-block mx-auto" src={`${getAssetUrl('app/dummy-1.png')}`} /></Col>
          <Col md="3"><img alt="ANNIVERSARY" className="img-fluid d-block mx-auto" src={`${getAssetUrl('app/dummy-2.png')}`} /></Col>
          <Col md="3"><img alt="ANNIVERSARY" className="img-fluid d-block mx-auto" src={`${getAssetUrl('app/dummy-3.png')}`} /></Col>
          <Col md="3"><img alt="ANNIVERSARY" className="img-fluid d-block mx-auto" src={`${getAssetUrl('app/dummy-1.png')}`} /></Col>
        </Row>
      </Container>
    </BlockContainer> */}
    <BlockContainer padding={3}>
      <Container>
        <Row className="my-4 d-flex justify-content-center">
            <Col lg="7" className="d-flex flex-column justify-content-center p-3">
              <div className="lead mb-0 text-justify">
                <p>Sorosoro Ibaba Development Cooperative started manufacturing feeds for livestock last March 1987 and the workflow and production process was certified by the International Organization for Standardization under ISO 9001:2011 last 2011 and upgraded to ISO 9001:2015 last November 2018.</p>
              </div>
            </Col>
            <Col lg="3" className="p-0">
               <img alt="ANNIVERSARY" className="img-fluid d-block mx-auto" src={getAssetUrl('agriculture/250-agri-tale-1.png')} />
            </Col>
        </Row>
        <Row className="my-4 d-flex justify-content-center">
            <Col
              sm={{
                order: 2
              }}
              lg={{
                size: 3,
                order: 1
              }}
              className="p-0"
            >
               <img alt="ANNIVERSARY" className="img-fluid d-block mx-auto" src={getAssetUrl('agriculture/250-agri-tale-2.png')} />
            </Col>
            <Col
              sm={{
                order: 1
              }}
              lg={{
                size: 7,
                order: 2
              }}
              className="d-flex flex-column justify-content-center p-3"
            >
              <div className="lead mb-0 text-justify">
                <p>SIDC expand to poultry business initiated last May 19, 2008. SIDC Layer Farm produce 60,000 eggs that will cater regular supply of eggs to its member and customer.</p>
              </div>
            </Col>
        </Row>
        <Row className="my-4 d-flex justify-content-center">
            <Col lg="7" className="d-flex flex-column justify-content-center p-3">
              <div className="lead mb-0 text-justify">
                <p>SIDC Rice mill which started in 2002 in Matungao, Soccoro, Oriental Mindoro has been complementing the rice demands of SIDC’s Copp Stores. From the 220 sacks per day production, it has now increased to 1600 per day with the use of the upgraded rice mill in Naujan, Oriental Mindoro which started its production last November 18, 2021.</p>
              </div>
            </Col>
            <Col lg="3" className="p-0">
               <img alt="ANNIVERSARY" className="img-fluid d-block mx-auto" src={getAssetUrl('agriculture/250-agri-tale-3.png')} />
            </Col>
        </Row>
        <Row className="my-4 d-flex justify-content-center">
            <Col
              sm={{
                order: 2
              }}
              lg={{
                size: 3,
                order: 1
              }}
              className="p-0"
            >
               <img alt="ANNIVERSARY" className="img-fluid d-block mx-auto" src={getAssetUrl('agriculture/250-agri-tale-4.png')} />
            </Col>
            <Col
              sm={{
                order: 1
              }}
              lg={{
                size: 7,
                order: 2
              }}
              className="d-flex flex-column justify-content-center p-3"
            >
              <div className="lead mb-0 text-justify">
                <p>SIDC started the KoopLikas Production in its Green Farm in 2013 and shifted its operation from production to distribution. This organic fertilizer is available at all SIDC Coop Stores nationwide and is proven effective for ornaments and crops because of its quality.</p>
              </div>
            </Col>
        </Row>
      </Container>
    </BlockContainer>
    <CoreValues />
  </Layout>
)

export default IndexPage
